// import React from "react";
import dummyAvatar from "../assets/avatar.svg";
import starIcon from "../icons/icons8-star-30.png";
import lineBreaker from "../assets/line breaker pattern.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Testimoni = () => {
  const renderStars = (starCount) => {
    let stars = [];
    for (let i = 0; i < starCount; i++) {
      stars.push(<img className="h-6 w-6" src={starIcon} alt="" key={i} />);
    }
    return stars;
  };

  return (
    <div className="grid p-6 h-fit py-12">
      <div className="place-self-center flex flex-col gap-2 items-center justify-center text-center max-w-[700px]">
        <img className="h-36 w-36 rounded-full" src={dummyAvatar} alt="" />
        <h2 className="text-[--h2-color] font-bold text-xl">John Smith</h2>
        <div className="flex gap-1">{renderStars(4)}</div>
        <p className="text-[--p-color] font-semibold italic">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
          aspernatur eius excepturi incidunt iste maxime suscipit facilis unde
          deserunt repudiandae esse possimus ipsa error odio dicta totam, cumque
          quod ex, reiciendis mollitia tenetur.
        </p>
      </div>
    </div>
  );
};

const Testimonials = () => {

  return (
    <section className="py-12 bg-gradient-to-r from-gray-300 to-gray-50 h-fit max-h-screen overflow-hidden md:px-8">
      <h1 className="text-center text-4xl font-semibold text-[--h1-color]">
        TESTIMONIALS
      </h1>
      <div className="flex justify-center mb-8">
        <img className="h-8" src={lineBreaker} alt="" />
      </div>
      {/* <!-- Slider main container --> */}
      <Swiper
      spaceBetween={100}
      slidesPerView={1}
      navigation={true} modules={[Navigation, Pagination]}
      pagination={true}
    >
      <SwiperSlide><Testimoni/></SwiperSlide>
      <SwiperSlide><Testimoni/></SwiperSlide>
      <SwiperSlide><Testimoni/></SwiperSlide>
      <SwiperSlide><Testimoni/></SwiperSlide>
      
    </Swiper>

    </section>
  );
};

export default Testimonials;
