import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <Analytics debug={false}/>
      <SpeedInsights/>
    </>
  );
};

export default Layout;
