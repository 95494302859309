import React from "react";
import Support from "../components/Support";

const SupportPage = () => {
  return (
    <>
      <div className="my-16">
        <Support />
      </div>
    </>
  );
};

export default SupportPage;
