import React, { useEffect, useState } from "react";
import CodingImage from "../assets/5522937.jpg";
import { Link, useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { PiPasswordFill } from "react-icons/pi";
import axios from "axios";
import {userAuthenticated, LoginValidation, getRandomId } from "../utils/util";
import { IoHomeOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Login = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const validUser = userAuthenticated(document.cookie);
    validUser ? navigate("/home") : navigate("/login");
    // eslint-disable-next-line
  }, []);

  //storing user inputs
  const [loginData, setLoginData] = useState({
    txnId: getRandomId(),
    email: "",
    password: "",
  });
  const [visiblePass, setVisiblePass] = useState(false);

  // axios post method to hit login api
  axios.defaults.withCredentials = true;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validation = LoginValidation(loginData);
    if (!validation) {
      setLoading(true);
      try {
        const resp = await axios.post(
          "https://codemap-backend-production.up.railway.app/api/login",
          loginData
        );
        console.log("resp = " + JSON.stringify(resp));
        const result = resp.data;
        if (result.apiResponse === 0 && result.data === true) {
          localStorage.setItem("userEmail", loginData.email);
          toast.success("Success", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          navigate("/home");
        } else {
          if (result.apiResponse === "1") {
            toast.error("User Doesn't Exists", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
          }
          if (result.apiResponse === "WP") {
            toast.error("Incorrect Password", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
          }
        }
      } catch (err) {
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    } else {
      toast.warn(`${validation}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      {/* main div  */}
      {/* <!-- component --> */}
      <div className="bg-[--form-bg-color] font-semibold flex justify-center items-center h-screen overflow-hidden relative">
        {/* toast container  */}
        <ToastContainer />
        {/* back to landing page link  */}
        <Link
          to={"/"}
          className="absolute text-blue-800 lg:text-white top-12 -left-24 transition-all delay-75 hover:left-2 z-10 flex gap-4 py-2 px-4 backdrop-blur bg-black lg:bg-white bg-opacity-15 lg:bg-opacity-30 rounded-full"
        >
          Go Back
          <IoHomeOutline size={20} />
        </Link>

        {/* <!-- Left: Image --> */}
        <div className="w-4/5 h-screen hidden lg:block relative">
          <img
            src={CodingImage}
            alt=""
            className="object-cover w-full h-full"
          />
          <div className="absolute top-[15%] left-1/2 -translate-x-1/2">
            <h1 className="text-4xl text-[--heading-color]">Keep it special</h1>
            <p className="text-xl text-[--tagline-color]">
              Capture your personal memory in unique way, anywhere.
            </p>
          </div>
        </div>

        {/* <!-- Right: Login Form --> */}
        <div className="lg:p-36 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <h2 className="text-3xl font-bold mb-2 text-[--font-color]">
            Codemap
          </h2>
          <p className="mb-4 font-semibold">Sign in to access your account</p>
          <form onSubmit={handleSubmit}>
            {/* <!-- email Input --> */}
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-600">
                Email
              </label>
              <div className="relative inline-block w-full">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 pl-10"
                  autoComplete="off"
                  placeholder=" "
                  onChange={(e) =>
                    setLoginData({ ...loginData, email: e.target.value })
                  }
                />
                {/* email icon  */}
                <MdEmail className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2" />
              </div>
            </div>

            {/* <!-- Password Input --> */}
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-600">
                Password{" "}
              </label>
              <div className="relative inline-block w-full">
                <input
                  type={`${visiblePass ? "text" : "password"}`}
                  id="password"
                  name="password"
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 pl-10"
                  autoComplete="off"
                  placeholder=" "
                  onChange={(e) =>
                    setLoginData({ ...loginData, password: e.target.value })
                  }
                />
                {/* password icon  */}
                <PiPasswordFill className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2" />

                {/* hidepass icon  */}
                {loginData.password !== "" ? (
                  <IoEyeOutline
                    title="hide"
                    onClick={() => setVisiblePass(false)}
                    className={`${
                      visiblePass ? "" : "hidden"
                    } absolute top-1/2 right-4 -translate-y-1/2 select-none cursor-pointer`}
                  />
                ) : (
                  <></>
                )}
                {/* unhide pass icon  */}
                <IoEyeOffOutline
                  title="unhide"
                  onClick={() => setVisiblePass(true)}
                  className={`${
                    visiblePass ? "hidden" : ""
                  } absolute top-1/2 right-4 -translate-y-1/2 select-none cursor-pointer`}
                />
              </div>
            </div>

            {/* <!-- Forgot Password Link --> */}
            <div className="mb-6 text-[--anchortag-color]">
              <Link to="/forgotpassword" className="hover:underline">
                Forgot Password?
              </Link>
            </div>

            {/* <!-- Login Button --> */}
            {!Loading ? (
              <button
                type="submit"
                className="bg-[--button-color] hover:bg-blue-600 text-white font-semibold rounded-md py-2 px-4 w-full"
              >
                Sign In
              </button>
            ) : (
              <button
                type="button"
                class="flex justify-center items-center px-4 py-2 w-full font-semibold leading-6 text-white transition duration-150 ease-in-out bg-blue-700 rounded-md shadow cursor-not-allowed hover:bg-blue-800"
                disabled
              >
                <svg
                  class="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Loading...
              </button>
            )}
          </form>

          {/* <!-- Sign up  Link --> */}
          <div className="mt-6 text-[--anchortag-color] text-center">
            <Link to="/signup" className="hover:underline">
              Create New Account
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
