import { createSlice } from '@reduxjs/toolkit';
import { queryCoursesCheck } from '../../utils/util';


export const courseContentSlice = createSlice({
    name: 'queryCourse',
    initialState: {
        courses: queryCoursesCheck(),
    },
    reducers: {
        courseAdd: (state, action) => {
            const newCourse = action.payload;
            const existingCourse = state.courses.find(course => course.id === newCourse.id);
            if (!existingCourse) {
                state.courses.push(newCourse);
                localStorage.setItem("queryCourse", JSON.stringify(state.courses));
            }
        }
    },
});


export const { courseAdd } = courseContentSlice.actions

export default courseContentSlice.reducer