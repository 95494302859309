import React from "react";
import instructorImg from "../assets/ChiranjeebNayak_Profile.jpeg-removebg-preview.png";

const Instructor = () => {
  return (
    <section className="py-8 px-4 sm:p-12 bg-gradient-to-l from-gray-300 to-gray-50 h-fit max-w-screen">
      <div className="flex flex-wrap gap-4 lg:px-12 md:gap-0 items-center justify-center md:justify-between border-b-2 border-gray-500 py-6 overflow-hidden">
        <h1 className="text-2xl md:text-4xl font-semibold text-[--h3-color]">
          Meet Your Instructor
        </h1>
        <a
          className="px-8 py-4 bg-[--button-color] rounded-md text-white font-semibold hover:bg-blue-700"
          href="/home"
        >
          Know More
        </a>
      </div>

      <div className="h-fit lg:h-[60vh] w-[90%] m-auto mt-8 rounded-3xl shadow-xl overflow-hidden bg-blue-100">
        <div className="flex flex-col lg:flex-row lg:justify-end items-center relative h-full w-full">
          <img
            className="w-fit h-fit md:h-fit max-w-[80vw] md:max-w-[35vw] lg:max-w-[30vw] static lg:absolute z-10 -top-14 2xl:top-0 left-0 lg:left-10"
            src={instructorImg}
            alt="CEO"
          />
          <div className="h-fit lg:w-3/5 p-8">
            <h2 className="text-2xl md:text-3xl font-semibold my-2">
              Chiranjeed Nayak
            </h2>
            <h4 className="text-lg md:text-xl font-semibold my-2">
              Founder- Codemap,SDE-3 @Walmart, EX- Juspay
            </h4>
            <p className="text-sm md:text-lg font-medium my-6">
              Chiranjeeb is a Software Engineer and a Youtuber, primarily known
              for his Coding and Software Engineering skills. He is quite a
              popular figure among students as well as working professionals on
              various social media platforms, his YouTube and LinkedIn profiles
              amassing over 3k subscribers. He's also mentored 100+ students so
              far. He has done his B.Tech (CSE) from the National Institute of
              Science and Technology,and worked in Walmart and Juspay.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Instructor;
