import React, { useEffect } from "react";
import Hero from "../components/Hero";
import Counter from "../components/Counter";
import Feature from "../components/Feature";
import Instructor from "../components/Instructor";
import Placement from "../components/Placement";
import Courses from "../components/Courses";
import Joinus from "../components/Joinus";
import Testimonials from "../components/Testimonials";
import { userAuthenticated } from "../utils/util";
import { useNavigate } from "react-router-dom";

const Landing = () => {  
  const navigate = useNavigate();
  useEffect(() => {
    const validUser = userAuthenticated(document.cookie);
    !validUser ? navigate("/") : navigate("/home");
    // eslint-disable-next-line
  }, []);

  const freeCourses = [
    {
      id: 34678,
      courseCreator: "Chiranjeeb Nayak",
      ratings: 4,
      title: "C++ For Beginners",
      about: "Introduction to C++ || Your First Program in C++",
      thumbnail:
        "https://i.ytimg.com/vi/QCfzudr3LSQ/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLDfGgJtDA7OoPqv40DCGwQRqD25jg",
      source:
        "https://www.youtube.com/watch?v=QCfzudr3LSQ&list=PLPzlWghXWFfG3AuEjdyh9bzK7IMMfKiV5&pp=iAQB",
    },
    {
      id: 3748937,
      courseCreator: "Chiranjeeb Nayak",
      ratings: 4,
      title: "DSA",
      about: "Placement Course || Data structure and algorithm",
      thumbnail:
        "https://i.ytimg.com/vi/Tp08hODccmI/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLC4WjnXYcrS9QGm-_WjAn_z6Oal5g",
      source:
        "https://www.youtube.com/watch?v=Tp08hODccmI&list=PLPzlWghXWFfE2uXDL0ZFNfdO1kXxiqI-t&pp=iAQB",
    },
    {
      id: 2030972,
      courseCreator: "Chiranjeeb Nayak",
      ratings: 4,
      title: "Android Studio",
      about: "Complete Beginners Tutorial of Android Studio",
      thumbnail:
        "https://i.ytimg.com/vi/HHkatk-P8jM/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLDYNy_OrgaICSdD7rlFqIbNjdJk5Q",
      source:
        "https://www.youtube.com/watch?v=HHkatk-P8jM&list=PLPzlWghXWFfEDN5SMCN6vpwmIu_BAJBnk&pp=iAQB",
    },
    {
      id: 209353,
      courseCreator: "Chiranjeeb Nayak",
      ratings: 4,
      title: "HTML Tutorials",
      about: "Complete HTML Tutorials with project",
      thumbnail:
        "https://i.ytimg.com/vi/qHEY9sq9LQo/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLCg5gJBLII83T7cN5HLOIp9Q6Ps2A",
      source:
        "https://www.youtube.com/watch?v=qHEY9sq9LQo&list=PLPzlWghXWFfF7OFpmEZoHc96m-D6NBECX&pp=iAQB",
    },
  ];
  
  return (
    <>
      <Hero />
      <Counter />
      <Feature />
      <Instructor />
      <Placement />
      <Courses course={freeCourses} />
      <Testimonials />
      <Joinus />
    </>
  );
};

export default Landing;
