import React from "react";
import youtubeIcon from "../icons/icons8-youtube-48.png";
import instaIcon from "../icons/icons8-instagram-48.png";
import linkdeinIcon from "../icons/icons8-linkedin-48.png";

const Joinus = () => {
  return (
    <section className="py-20 px-4 bg-gradient-to-l from-gray-300 to-gray-50">
      <div>
        <div>
          <h2 className="text-center font-bold text-3xl text-[--h2-color]">
            JOIN US ON
          </h2>
          {/* <p className='text-center font-semibold text-xl'> </p> */}
        </div>
        <div className="grid grid-cols-1 gap-4 mt-6 md:grid-cols-2 lg:grid-cols-3 items-center px-12">
          <div className="flex flex-col justify-center items-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@Codemap"
            >
              <img src={youtubeIcon} alt="" />
            </a>
            <p className="font-semibold text-xl text-[--p-color]">Youtube</p>
            <h3 className="font-semibold text-xl text-[--h3-color]">CodeMap</h3>
          </div>
          <div className="flex flex-col justify-center items-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/chiranjeebnayak.37/"
            >
              <img src={instaIcon} alt="" />
            </a>
            <p className="font-semibold text-xl text-[--p-color]">Instagram</p>
            <h3 className="font-semibold text-xl text-[--h3-color]">
              chiranjeebnayak.37
            </h3>
          </div>
          <div className="flex flex-col justify-center items-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/ChiranjeebNayak"
            >
              <img src={linkdeinIcon} alt="" />
            </a>
            <p className="font-semibold text-xl text-[--p-color]">Linkedin</p>
            <h3 className="font-semibold text-xl text-[--h3-color]">
              Chiranjeeb Nayak
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Joinus;
