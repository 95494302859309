import React from "react";
import { MdOutlineDeleteForever } from "react-icons/md"; // delete icon
import { HiOutlineStar } from "react-icons/hi2"; // blank star icon
import { HiStar } from "react-icons/hi2"; // filled star icon
import { FaTag } from "react-icons/fa";
import emptyCartImage from "../assets/bloom-a-man-looks-at-a-blank-sheet-of-paper-in-puzzlement.png";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../redux/cart/cartSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);

  const totalAmount = () => {
    let balance = 0;
    cartItems.map((m) => (balance += parseInt(m.price)));
    return balance;
  };

  const totalNoDiscountAmount = () => {
    let balance = 0;
    cartItems.map((m) => (balance += parseInt(m.previousPrice)));
    return balance;
  };

  const removeCartItem = (cid) => {
    dispatch(removeFromCart({ id: cid }));
    toast.warn("Removed from cart", {
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  //   ratings star return function
  const starCountReturn = (curren_ratings) => {
    let currentStar = curren_ratings;
    let remainingStar = 5 - currentStar;
    let ratings = [];
    for (let i = 1; i <= currentStar; i++) {
      ratings = [<HiStar key={i + "filledStar"} />, ...ratings];
    }
    for (let j = 1; j <= remainingStar; j++) {
      ratings = [...ratings, <HiOutlineStar key={j + "blankStar"} />];
    }
    return ratings;
  };

  return (
    <>
      <div className="mt-24">
        {/* toast container  */}
        <ToastContainer />

        {/* all cart items heading  */}
        <h1 className="text-3xl mx-28 font-semibold text-[--h1-color]">
          Your Cart
        </h1>

        {cartItems.length > 0 ? (
          <>
            {/* main conatiner  */}
            <div className="container_cart pt-4 min-h-screen flex flex-col items-center lg:items-start lg:justify-evenly lg:flex-row justify-start gap-12 lg:gap-0 lg:px-10">
              {/* second container  */}
              <div className="container2_cart w-[90%] lg:w-[65%] lg:max-w-[65%]">
                {/* total items count heading  */}
                <h3 className="text-lg font-semibold text-[--h3-color] my-2">
                  {cartItems.length}{" "}
                  {cartItems.length <= 1 ? "course" : "courses"} in cart
                </h3>

                {/* all course present in cart container  */}
                <div className="allCoursesContainer_cart">
                  {cartItems.map((m, i) => (
                    <>
                      {/* each course box  */}
                      <div
                        key={m}
                        id={m.id}
                        className="py-4 border-t border-gray-400"
                      >
                        <div className="eachCourseBox justify-between flex flex-wrap h-fit p-2 items-center">
                          {/* course thumbnail and details container  */}
                          <div className="flex gap-4">
                            {/* course thumbnail  */}
                            <div className="courseThumbnail object-cover w-32">
                              <img src={m.thumbnail} alt="course thumbnail" />
                            </div>

                            {/* course title author ratings div  */}
                            <div className="courseDetials h-[90%]">
                              {/* course title  */}
                              <p className="text-sm font-medium my-1">
                                {m.title}
                              </p>

                              {/* course author  */}
                              <p className="text-xs my-1">
                                by {m.courseCreator}
                              </p>

                              {/* course ratings  */}
                              <p className="flex items-center text-xs my-1">
                                {m.ratings}{" "}
                                <span className="text-yellow-500 flex">
                                  {starCountReturn(m.ratings)}
                                </span>
                              </p>

                              {/* course duration / lecture count / courselevel para  */}
                              <p className="text-xs my-1">
                                <span>{m.duration}</span> &bull;
                                <span>{m.lectureCount}</span> &bull;
                                <span>{m.level}</span>
                              </p>
                            </div>
                          </div>

                          {/* remove course btn and price  */}
                          <div className="course_price_btns flex gap-4">
                            {/* remove item btn with icon  */}
                            <button
                              onClick={() => removeCartItem(m.id)}
                              className="flex items-center gap-1 font-semibold text-blue-500 mx-4 hover:text-blue-700"
                            >
                              <MdOutlineDeleteForever size={20} /> Remove Item
                            </button>

                            {/* course prev price and current price  */}
                            <div className="p-4">
                              <p className="line-through text-xs font-semibold">
                                &#8377;{m.previousPrice}
                              </p>
                              <p className="flex gap-1 items-center text-[--price-color] font-bold text-lg">
                                <span>&#8377;{m.price}</span>
                                <FaTag size={15} />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>

              {/* checkoutBox */}
              <div className="checkoutBox w-[90%] md:w-[70%] lg:w-[25%] lg:max-w-[27%] h-fit min-h-[30vh] lg:border-l border-gray-400 p-4 mb-20 lg:mb-0">
                {/* total amount  */}
                <h1 className="text-3xl mb-4 font-semibold">Summary</h1>
                <h1 className="text-lg flex items-center justify-between font-semibold my-3">
                  <span>Subtotal</span>{" "}
                  <span>&#8377;{totalNoDiscountAmount()}</span>
                </h1>
                <h1 className="text-lg flex items-center justify-between font-semibold text-red-400 my-3">
                  <span>Discount</span>{" "}
                  <span>-&#8377;{totalNoDiscountAmount() - totalAmount()}</span>
                </h1>
                <h1 className="text-xl flex items-center justify-between font-semibold text-[--price-color] my-3">
                  <span>Total</span> <span>&#8377;{totalAmount()}</span>
                </h1>

                <p className="text-right text-sm font-semibold">
                  Including GST
                </p>
                <button className="bg-[--button-color] hover:bg-[--button-hover-color] text-white py-3 w-full rounded font-bold transition-all delay-75">
                  Checkout
                </button>
              </div>
            </div>
          </>
        ) : (
          // empty cart view
          <>
            <div className="min-h-screen p-8 flex flex-col items-center justify-start">
              <img className="h-[40vh]" src={emptyCartImage} alt="empty" />
              <h1 className="text-2xl font-semibold">Your Cart is Empty</h1>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;
