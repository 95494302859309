import React from "react";
import playIcon from "../icons/icons8-play-button-50.png";
import { NavLink } from "react-router-dom";


const CourseCard = ({ about, courseTitle, id, thumbnail }) => {
  return (
    <>
      {/* course card  */}
      <div className=" max-w-64 overflow-hidden rounded-md grid gap-2 p-4 shadow-lg">
        {/* coure thumbnail  */}
        <div className="group relative shadow-sm">
          <img
            src={thumbnail}
            alt=""
          />
          {/* thumbnail play button and blur hover  */}
          <div className="group-hover:opacity-100 grid w-full absolute top-0 left-0 bottom-0 right-0 backdrop-blur-sm opacity-0 transition-all delay-75">
            <NavLink
              to={`/course/video/${id}`}
              className="cursor-pointer place-self-center h-12 w-12 bg-white rounded-full flex items-center justify-center p-2"
            >
              <img src={playIcon} alt="" />
            </NavLink>
          </div>
        </div>

        {/* course label and continue Watching button  */}
        <div className="grid">
          <label className="font-semibold text-md">{courseTitle}</label>
          <p className="italic ">{about}</p>
          <NavLink
            className="mt-4 py-2 font-semibold border-t-2 border-gray-500 hover:text-gray-800 hover:underline underline-offset-2"
            to={`/course/video/${id}`}
          >
            Continue Watching
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default CourseCard;
