import React from "react";

const Support = () => {
  return (
    <>
      <div className=" grid items-center justify-center py-4 px-8">
        <form className="bg-gray-300 rounded-md max-w-96">
          <h1 className="text-center font-bold text-[--h1-color] text-2xl mt-4">
            Facing Issues?
          </h1>
          <div className="grid grid-cols-1 items-center justify-center gap-3 p-8">
            <input
              className="px-4 py-2 rounded-md"
              type="email"
              placeholder="Your Email"
              required
            />
            <input
              className="px-4 py-2 rounded-md"
              type="tel"
              placeholder="Your Mobile No"
              required
            />
            <input
              className="px-4 py-2 rounded-md"
              type="text"
              placeholder="Mention The Course"
              required
            />
            <textarea
              className="px-4 py-2 rounded-md"
              type="text"
              cols={50}
              rows={4}
              placeholder="Describe Your Issue"
              required
            ></textarea>
            <button
              className="px-4 py-2 bg-[--button-color] rounded-md font-semibold text-white"
              type="submit"
            >
              Submit Issue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Support;
