import React, { useEffect, useState } from "react";
import userAvatar from "../assets/user.png";
import axios from "axios";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { userAuthenticated } from "../utils/util";

const Profile = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const validUser = userAuthenticated(document.cookie);
    !validUser && navigate("/login");
    // eslint-disable-next-line
  }, []);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  axios.defaults.withCredentials = true;
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const email = localStorage.getItem("userEmail");
        const resp = await axios.post("http://localhost:5000/api/profile", {
          email: email,
        });
        const data = resp.data.appData;
        setUserData({
          name: data.name,
          email: data.email,
          phone: data.phone,
        });
      } catch (err) {
        console.log("Error Fetching userdata" + err);
      }
    };
    fetchProfile();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (userData.name !== "" && userData.email !== "") {
      const updatedData = {
        email: localStorage.getItem("userEmail"),
        newEmail: userData.email,
        newName: userData.name,
        phone: userData.phone,
      };
      const response = await axios.post(
        "http://localhost:5000/api/profile/update",
        updatedData
      );
      const responseData = response.data;
      if (responseData.apiResponse === 0 && responseData.data === true) {
        localStorage.setItem("userEmail", userData.email);
      }
    }
  };

  const editName = () => {
    let showName = document.querySelector(".showName");
    let editName = document.querySelector(".editNameInput");
    showName.classList.remove("flex");
    showName.classList.add("hidden");
    editName.classList.remove("hidden");
    editName.classList.add("flex");
  };
  const cancelNameEdit = () => {
    let showName = document.querySelector(".showName");
    let editName = document.querySelector(".editNameInput");
    if (userData.name !== "") {
      showName.classList.remove("hidden");
      showName.classList.add("flex");
      editName.classList.remove("flex");
      editName.classList.add("hidden");
    }
  };
  const cancelEmailEdit = () => {
    let showEmail = document.querySelector(".showEmail");
    let editEmail = document.querySelector(".editEmailInput");
    if (userData.email !== "") {
      showEmail.classList.remove("hidden");
      showEmail.classList.add("flex");
      editEmail.classList.remove("flex");
      editEmail.classList.add("hidden");
    }
  };
  const editEmail = () => {
    let showEmail = document.querySelector(".showEmail");
    let editEmail = document.querySelector(".editEmailInput");
    showEmail.classList.remove("flex");
    showEmail.classList.add("hidden");
    editEmail.classList.remove("hidden");
    editEmail.classList.add("flex");
  };

  return (
    <>
      <div className="flex items-center justify-center w-full h-screen bg-gray-200">
        <div className="bg-white border-2 rounded border-gray-500 w-[90%] h-fit md:w-2/4">
          <div className="p-6 text-center border-b-2 border-gray-300">
            <h1 className="text-2xl font-semibold text-[--h1-color]">
              Your Profile
            </h1>
            <p className="text-sm font-semibold text-gray-600">
              Add/Update your information
            </p>
          </div>
          <div className="p-8 grid items-center justify-center">
            <div className="grid items-center justify-center">
              {userData.name !== "" || userData.email !== "" ? (
                <div className="font-semibold text-6xl w-24 h-24 rounded-full bg-blue-400 flex items-center justify-center">
                  {userData.name
                    ? userData.name.charAt(0).toUpperCase()
                    : userData.email.charAt(0).toUpperCase()}
                </div>
              ) : (
                <div>
                  <img className={`w-24 h-24`} src={userAvatar} alt="" />
                </div>
              )}
            </div>
            <form
              className="flex flex-col items-center justify-center gap-4 p-4 max-w-96"
              onSubmit={handleUpdate}
            >
              <div className="showName flex items-center w-full justify-between border-b-[1px] border-b-gray-600 px-4 py-2">
                {userData.name}{" "}
                <FaRegEdit
                  className="cursor-pointer"
                  size={20}
                  title="Edit Name"
                  onClick={editName}
                />
              </div>
              <div className="editNameInput w-full hidden items-center justify-between border-b-[1px] border-b-gray-600 px-4 py-2">
                <input
                  className={` outline-none ${
                    userData.name === ""
                      ? "placeholder-red-600"
                      : "placeholder-gray-600"
                  }`}
                  onChange={(e) =>
                    setUserData({ ...userData, name: e.target.value })
                  }
                  type="text"
                  placeholder={`${
                    userData.name === "" ? "Please Enter Name" : "Full Name"
                  } `}
                  value={userData.name}
                  name="full name"
                  required
                />
                <MdOutlineCancel
                  className="cursor-pointer"
                  onClick={cancelNameEdit}
                  title="cancel"
                  size={24}
                />
              </div>

              <div className="showEmail flex items-center w-full justify-between gap-6 border-b-[1px] border-b-gray-600 px-4 py-2">
                {userData.email}{" "}
                <FaRegEdit
                  className="cursor-pointer"
                  size={20}
                  title="Edit Email"
                  onClick={editEmail}
                />
              </div>
              <div className="editEmailInput w-full hidden gap-4 items-center justify-between border-b-[1px] border-b-gray-600 px-4 py-2">
                <input
                  className={`outline-none ${
                    userData.email === ""
                      ? "placeholder-red-600"
                      : "placeholder-gray-600"
                  }`}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  type="email"
                  placeholder={`${
                    userData.email === "" ? "Please Enter Email" : "Email ID"
                  } `}
                  value={userData.email}
                  name="Emailid"
                  required
                />
                <MdOutlineCancel
                  className="cursor-pointer"
                  onClick={cancelEmailEdit}
                  title="cancel"
                  size={24}
                />
              </div>

              <p className="flex items-center w-full justify-between border-b-[1px] border-b-gray-600 select-none px-4 py-2">
                {userData.phone}{" "}
                <FaRegEdit
                  className="text-gray-400 cursor-pointer"
                  size={20}
                  title="Edit Disabled"
                />
              </p>
              <button
                className="px-4 py-2 bg-[--button-color] rounded-md text-white select-none"
                type="submit"
              >
                save changes
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
