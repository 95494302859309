import React, { useState } from "react";
import vid from "../assets/videos/C++ In One Video _ Part 1 __ Introduction to C++ __ Your First Program in C++.mp4";

const ShowVideo = ({ videoData, activeVideo, visibleSidebar }) => {
  const sectionIndex = activeVideo.sectionIndex;
  const videoIndex = activeVideo.videoIndex;
  const video = videoData[sectionIndex].sectionVideos[videoIndex];
  const [activeOn, setactiveOn] = useState("overview");
  
  return (
    <div className="flex flex-col w-full h-fit items-end">
      <div
        className={`${
          visibleSidebar ? "w-4/5" : "w-full"
        } max-h-4/5 bg-gray-800 transition-all delay-100 flex justify-center`}
      >
        {/* <video
          src={`${videoData[sectionIndex].sectionVideos[videoIndex].videoUrl}`}
        ></video> */}
        <video
          className={`${
            visibleSidebar ? "h-[60vh]" : "h-[80vh]"
          } w-fit transition-all delay-100`}
          src={vid}
          controls
        ></video>
      </div>
      <div
        className={`${
          visibleSidebar ? "w-2/3" : "w-full"
        } transition-all delay-100 p-4`}
      >

        <div
          className={` w-full flex gap-4 font-semibold text-lg justify-center border-b-2 border-b-gray-300`}
        >
          <span
            onClick={() => setactiveOn("overview")}
            className={`cursor-pointer ${
              activeOn === "overview" ? "border-b-2 border-b-gray-500" : ""
            }`}
          >
            Overview
          </span>
          <span
            onClick={() => setactiveOn("support")}
            className={`cursor-pointer ${
              activeOn === "support" ? "border-b-2 border-b-gray-500" : ""
            }`}
          >
            Support
          </span>
        </div>

        {activeOn === "overview" ? (
          <>
            <h3
              className={`text-xl font-semibold mt-2 ${
                visibleSidebar ? "" : "ml-24"
              } transition-all delay-100`}
            >
              {activeVideo.sectionIndex !== "" ? video.videoTitle : ""}
            </h3>
            <div
              className={`${
                visibleSidebar ? "w-full" : "w-4/5"
              } transition-all delay-100 m-auto py-4`}
            >
              <div className="border-b-[1px] border-b-gray-400 px-4 py-2">
                <p>Duration : {video.videoDuration}</p>
                <p>about this course bla bla bla</p>
              </div>
              <div className="border-b-[1px] border-b-gray-400 p-4 flex gap-24">
                <span>Features</span>{" "}
                <span>
                  Available on <b className="text-blue-800">iOS</b> and{" "}
                  <b className="text-blue-800">Android</b>
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center gap-4 w-full py-4">
              <h4 className="text-lg font-semibold">Any Doubt or Issue</h4>
              <form>
                <div className="flex flex-col items-start gap-4 w-full">
                  <input
                    className="w-full px-4 py-2 outline-none bg-transparent border-b-[1px] border-b-black"
                    type="text"
                    placeholder="Problem Topic"
                  />
                  <textarea
                    className="px-4 py-2 outline-none bg-transparent border-b-[1px] border-b-black"
                    name=""
                    placeholder="Write your query"
                    id=""
                    cols="50"
                    rows="3"
                  ></textarea>
                  <button
                    className="px-4 py-2 rounded bg-[var(--button-color)] hover:text-white hover:scale-95"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ShowVideo;
