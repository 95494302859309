import { createSlice } from '@reduxjs/toolkit'
import { checkCartItems } from '../../utils/util'


export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: checkCartItems(),
    },
    reducers: {
        addtoCart: (state, action) => {
            state.cart.push(action.payload)
            localStorage.setItem("userCart", JSON.stringify(state.cart))
        },
        removeFromCart: (state, action) => {
            state.cart = state.cart.filter(x => x.id !== action.payload.id);
            localStorage.setItem("userCart", JSON.stringify(state.cart))
        },
    },
})

// Action creators are generated for each case reducer function
export const { addtoCart, removeFromCart } = cartSlice.actions

export default cartSlice.reducer