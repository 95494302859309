import React, { useEffect, useState } from "react";
import CourseBox from "../components/CourseBox";
import axios from "axios";
import { getRandomId } from "../utils/util";
import { ToastContainer } from "react-toastify";

const AllCourses = () => {
  const [allCourse, setAllCourse] = useState([]);
  const userEmail = localStorage.getItem("userEmail");

  axios.defaults.withCredentials = true;
  useEffect(() => {
    const getAllCourses = async () => {
      try {
        const response = await axios.post(
          "https://codemap-backend-production.up.railway.app/api/allcourses",
          {
            email: userEmail,
            txnId: getRandomId(),
          }
        );
        setAllCourse(response.data.appData);
        console.log(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCourses();

    // eslint-disable-next-line
  }, []);

  const freeCourses = [
    {
      title: "C++ For Beginners",
      about: "Introduction to C++ || Your First Program in C++",
      thumbnail:
        "https://i.ytimg.com/vi/QCfzudr3LSQ/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLDfGgJtDA7OoPqv40DCGwQRqD25jg",
      source:
        "https://www.youtube.com/watch?v=QCfzudr3LSQ&list=PLPzlWghXWFfG3AuEjdyh9bzK7IMMfKiV5&pp=iAQB",
    },
    {
      title: "DSA",
      about: "Placement Course || Data structure and algorithm",
      thumbnail:
        "https://i.ytimg.com/vi/Tp08hODccmI/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLC4WjnXYcrS9QGm-_WjAn_z6Oal5g",
      source:
        "https://www.youtube.com/watch?v=Tp08hODccmI&list=PLPzlWghXWFfE2uXDL0ZFNfdO1kXxiqI-t&pp=iAQB",
    },
    {
      title: "Android Studio",
      about: "Complete Beginners Tutorial of Android Studio",
      thumbnail:
        "https://i.ytimg.com/vi/HHkatk-P8jM/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLDYNy_OrgaICSdD7rlFqIbNjdJk5Q",
      source:
        "https://www.youtube.com/watch?v=HHkatk-P8jM&list=PLPzlWghXWFfEDN5SMCN6vpwmIu_BAJBnk&pp=iAQB",
    },
    {
      title: "HTML Tutorials",
      about: "Complete HTML Tutorials with project",
      thumbnail:
        "https://i.ytimg.com/vi/qHEY9sq9LQo/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLCg5gJBLII83T7cN5HLOIp9Q6Ps2A",
      source:
        "https://www.youtube.com/watch?v=qHEY9sq9LQo&list=PLPzlWghXWFfF7OFpmEZoHc96m-D6NBECX&pp=iAQB",
    },
  ];

  return (
    <>
      <div className="mt-16">

        {/* toast container  */}
      <ToastContainer />

        {/* free courses section  */}
        <section className="px-8 py-12 bg-gradient-to-l from-gray-300 to-gray-50 min-h-[80vh]">
          <h1 className="text-center text-3xl text-[--h1-color] font-semibold">
            Free Available Courses
          </h1>
          <div className="grid grid-cols-1 gap-4 py-6 md:grid-cols-2 lg:grid-cols-4">
            {freeCourses.map((m, i) => (
              <CourseBox key={i} course={m} type={"free"} />
            ))}
          </div>
        </section>

        {/* paid courses section  */}
        <section className="px-8 py-12 bg-gradient-to-l from-gray-300 to-gray-50 min-h-[80vh]">
          <h1 className="text-center text-3xl text-[--h1-color] font-semibold">
            Premium Courses
          </h1>
          {allCourse[0] && (
            <div className="grid grid-cols-1 gap-4 py-6 md:grid-cols-2 lg:grid-cols-4">
              {allCourse.map((m, i) => (
                <CourseBox key={i} course={m} type={"premium"} />
              ))}
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default AllCourses;
