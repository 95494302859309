import { configureStore } from '@reduxjs/toolkit'
import cartSlice from './cart/cartSlice'
import courseContenSlice from './courseContent/courseContenSlice'


export const store = configureStore({
  reducer: {
    cart: cartSlice,
    courseQuery: courseContenSlice,
  },
})