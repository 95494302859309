import React from "react";
import { Link } from "react-router-dom";
import notFoundimg from "../assets/bubble-gum-error-404.gif";

const NotFound = () => {
  return (
    <>
      <section className="flex items-center h-full p-4 md:p-16 dark:bg-gray-50 dark:text-gray-800 bg-cover">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">

            <img src={notFoundimg} alt="not found gif" className=" h-[15rem] m-auto dark:text-gray-400" />
            <p className="text-2xl font-semibold md:text-3xl mb-8">
              Oops! Nothing to see here.
            </p>
            <Link
              to="/"
              className="px-8 py-3 font-semibold rounded dark:bg-violet-600 dark:text-gray-50"
            >
              Back to homepage
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
