import React from "react";

const Refund = () => {
  return (
    <div className="mt-16">
      <div className="pt-12 w-[80%] lg:w-[70%] mx-auto min-h-[60vh] md:min-h-[70vh]">
        <h1 className="text-2xl md:text-4xl text-center my-4 font-semibold">REFUND & CANCELLATION POLICY</h1>
        <p className="text-sm md:text-lg my-4 font-medium">
          There is a strict no refund & no cancellation policy. You are entitled
          to a refund only in the case where you have not been allotted the
          course after payment.
        </p>
      </div>
    </div>
  );
};

export default Refund;
