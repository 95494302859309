import React from "react";
import { Link } from "react-router-dom";
import myProfileIcon from "../icons/icons8-test-account-48.png";
import bookIcon from "../icons/icons8-book-50.png";
import helpIcon from "../icons/icons8-help-50.png";
import coursesIcon from "../icons/icons8-e-learning-32.png";
import logoutIcon from "../icons/icons8-logout-50.png";

const MyProfileMenu = ({ handleLogout, location }) => {
  return (
    // main container
    <div className="absolute hidden group-hover:block min-w-48 -translate-x-1/2 bg-gray-100 rounded-md shadow-lg">
      {/* all the list items  */}
      <ul className="grid gap-3 px-2 py-4">
        {/* my profile  */}
        <li
          className={`hover:text-blue-600 ${
            location.pathname === "/profile" ? "text-blue-600" : ""
          }`}
        >

            {/* myprofile link  */}
          <Link
            to="/profile"
            className="flex gap-2 items-center justify-start"
            href=""
          >
            <img className="h-6 w-6" src={myProfileIcon} alt="" />
            <span>My Profile</span>
          </Link>
        </li>

        {/* my learning  */}
        <li
          className={`hover:text-blue-600 ${
            location.pathname === "/mylearnings" ? "text-blue-600" : ""
          }`}
        >
          <Link
            to="/mylearnings"
            className="flex gap-2 items-center justify-start"
            href=""
          >
            <img className="h-6 w-6" src={bookIcon} alt="" />
            <span>My Learning</span>
          </Link>
        </li>

        {/* explore more courses  */}
        <li
          className={`hover:text-blue-600 ${
            location.pathname === "/courses" ? "text-blue-600" : ""
          }`}
        >
          <Link
            to="/courses"
            className="flex gap-2 items-center justify-start"
            href=""
          >
            <img className="h-6 w-6" src={coursesIcon} alt="" />
            <span>Explore Courses</span>
          </Link>
        </li>

        {/* help / support  */}
        <li
          className={`hover:text-blue-600 ${
            location.pathname === "/support" ? "text-blue-600" : ""
          }`}
        >
          <Link
            to="/support"
            className="flex gap-2 items-center justify-start"
            href=""
          >
            <img className="h-6 w-6" src={helpIcon} alt="" />
            <span>Support</span>
          </Link>
        </li>

        {/* logout  */}
        <li onClick={() => handleLogout()} className="hover:text-blue-600">
          <p className="flex cursor-pointer gap-2 items-center justify-start">
            <img className="h-6 w-6" src={logoutIcon} alt="" />
            <span>Logout</span>
          </p>
        </li>
      </ul>
    </div>
  );
};

export default MyProfileMenu;
