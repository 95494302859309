import { v4 as uuidv4 } from "uuid";

export const getRandomId = () => {
    const uuid = "CM" + uuidv4().replace(/-/g, '').slice(0, 25);
    return uuid;
}

export const getPhoneNumber = (phone) => {
    if (phone.length === 10) {
        return phone;
    }
    if (phone.length === 11 && phone[0] === '0') {
        const phone10Digit = phone.substring(1, 11);
        return phone10Digit;
    }
    if (phone.length === 12 && phone.substring(0, 2) === '91') {
        const phone10Digit = phone.substring(2, 12);
        return phone10Digit;
    }
    return phone;
}

export const isValidPhoneNumber = (phone) => {
    if (phone.length === 10) {
        return true;
    }
    if (phone.length === 11 && phone[0] === '0') {
        return true;
    }
    if (phone.length === 12 && phone.substring(0, 2) === '91') {
        return true;
    }
    return false;
}

const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload).keyForjwt;
}


export const userAuthenticated = (token) => {
    if (!token) {
        //console.log("cookies" + JSON.stringify(document.cookies))
        //console.log("token = " + token)
        if (token === undefined || token === null || token === "") {
            return false;
        } else {
            const [key, value] = token.split("=");
            const decoded = parseJwt(value);
            if (token === "undefined" || token === null || token === "") {
                return false;
            } else {
                if (key === "codemap-token" && decoded === localStorage.getItem("userEmail")) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
}

export const checkCartItems = () => {
    let items = JSON.parse(localStorage.getItem("userCart"));
    if (items === undefined || items === null) {
        localStorage.setItem("userCart", JSON.stringify([]));
        return JSON.parse(localStorage.getItem("userCart"));
    } else {
        return items;
    }
}

export const queryCoursesCheck = () => {
    let items = JSON.parse(localStorage.getItem("queryCourse"));
    if (items === undefined || items === null) {
        localStorage.setItem("queryCourse", JSON.stringify([]));
        return [];
    } else {
        return items;
    }
}


export const LoginValidation = (obj) => {
    // This regex will block the specified special characters
    const regex = /^[^!#$%&'*+/=?^_`{|}~]+$/;
    const emailValid = regex.test(obj.email);
    const forbiddenChars = /[-;:<>]/;
    if (obj.email !== "") {
        if (emailValid) {
            if (obj.password.length >= 6) {
                if (!forbiddenChars.test(obj.password)) {
                    return false;
                } else {
                    return "Password contains invalid symbols";
                }
            } else {
                return "Password Lenght must be 6";
            }
        } else {
            return "Invalid email format";
        }
    } else {
        return "Enter email please";
    }
};

export const signUpValidation = (obj) => {
    // This regex will block the specified special characters
    const regex = /^[^!#$%&'*+/=?^_`{|}~]+$/;
    const emailValid = regex.test(obj.email);
    const forbiddenChars = /[-;:<>]/;
    if (obj.name !== "") {
        if (obj.email !== "") {
            if (obj.phone !== "") {
                if (emailValid) {
                    if (obj.pass.length > 5) {
                        if (obj.pass !== obj.confPass) {
                            return "Password Mismatched";
                        } else {
                            if (!forbiddenChars.test(obj.pass)) {
                                return true;
                            } else {
                                return "Invalid symbols in password";
                            }
                        }
                    } else {
                        return "Password Length Must be 6";
                    }
                } else {
                    return "Invalid Email";
                }
            } else {
                return "Enter phone please";
            }
        } else {
            return "Enter email please";
        }
    } else {
        return "Enter name please";
    }
};


