import React, { useState } from "react";
//import AlertPopup from "../components/AlertPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPass = () => {
  const [sendOtp, setSendOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);

  const [resetData, setResetData] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getOtp = () => {
    if (resetData.email !== "") {
      setSendOtp(true);
    } else {
      setEmailEmpty(true);
    }
  };

  const verifyOtp = () => {
    setOtpVerified(true);
  };
  const resetPassword = () => {
    if (resetData.password !== "" && resetData.confirmPassword !== "") {
      if (resetData.password === resetData.confirmPassword) {
        toast.success("Password Changed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.warn("Password Mismatched", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900 relative">
        <ToastContainer />

        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
          <p className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img className="w-8 h-8 mr-2" src="#" alt="logo" />
            CodeMap
          </p>
          <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Change Password
            </h2>
            <form
              className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
              onSubmit={handleSubmit}
            >
              {!sendOtp ? (
                <>
                  <div>
                    <label
                      for="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        !emailEmpty
                          ? "dark:placeholder-gray-400"
                          : "placeholder-red-500"
                      }`}
                      placeholder={`${
                        emailEmpty ? "Enter Email" : "youremail@company.com"
                      }`}
                      required
                      onChange={(e) =>
                        setResetData({ ...resetData, email: e.target.value })
                      }
                      value={resetData.email}
                    />
                  </div>
                </>
              ) : (
                <>
                  {!otpVerified ? (
                    <>
                      <div>
                        <label
                          for="otp"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Enter OTP
                        </label>
                        <input
                          type="tel"
                          name="otp"
                          id="opt"
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="123456"
                          onChange={(e) =>
                            setResetData({ ...resetData, otp: e.target.value })
                          }
                          value={resetData.otp}
                          required
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <label
                          for="password"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          New Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="••••••••"
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                          onChange={(e) =>
                            setResetData({
                              ...resetData,
                              password: e.target.value,
                            })
                          }
                          value={resetData.password}
                        />
                      </div>
                      <div>
                        <label
                          for="confirm-password"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Confirm password
                        </label>
                        <input
                          type="password"
                          name="confirm-password"
                          id="confirm-password"
                          placeholder="••••••••"
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                          onChange={(e) =>
                            setResetData({
                              ...resetData,
                              confirmPassword: e.target.value,
                            })
                          }
                          value={resetData.confirmPassword}
                        />
                      </div>
                    </>
                  )}
                </>
              )}

              <button
                type="submit"
                onClick={getOtp}
                className={`w-full text-white bg-[var(--button-color)] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                  !sendOtp ? "" : "hidden"
                }`}
              >
                Get OTP
              </button>
              <button
                type="submit"
                onClick={verifyOtp}
                className={`w-full text-white bg-[var(--button-color)] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                  !otpVerified & sendOtp ? "" : "hidden"
                }`}
              >
                Verify OTP
              </button>
              <button
                type="submit"
                onClick={resetPassword}
                className={`w-full text-white bg-[var(--button-color)] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                  otpVerified ? "" : "hidden"
                }`}
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPass;
