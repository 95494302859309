import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addtoCart, removeFromCart } from "../redux/cart/cartSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { courseAdd } from "../redux/courseContent/courseContenSlice";
import axios from "axios";

const CourseBox = ({ course, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = `/course/details/${course.id}`;
  const courses = useSelector((state) => state.courseQuery.courses);

  const checkItemPresent = () => {
    let cartItems = JSON.parse(localStorage.getItem("userCart"));
    let foundItem = cartItems.find((x) => x.id === course.id);
    if (foundItem === undefined || foundItem === null) {
      return false;
    } else {
      return true;
    }
  };
  const [addedToCart, setAddedToCart] = useState(checkItemPresent());
  const addToCart = () => {
    dispatch(addtoCart(course));
    setAddedToCart(checkItemPresent());
    toast.success("Added to cart", {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const removeItem = () => {
    dispatch(removeFromCart({ id: course.id }));
    setAddedToCart(checkItemPresent());
    toast.warn("Removed from cart", {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  axios.defaults.withCredentials = true;
  const saveCourseContent = async (id) => {
    const coursePresent = courses.find((course) => course.id === Number(id));
    if (coursePresent === undefined) {
      const response = await axios.post(
        "https://codemap-backend-production.up.railway.app/api/course",
        { email: localStorage.getItem("userEmail") },
        { params: { id: id } }
      );
      dispatch(courseAdd(response.data.appData[0]));
    }
    navigate(`${url}`);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-2 px-4 py-8 rounded-md shadow-md hover:shadow-xl hover:scale-[1.02] transition delay-200 bg-white">
        {type === "free" ? (
          <img
            className="rounded-md cursor-pointer"
            src={course.thumbnail}
            alt=""
          />
        ) : (
          <img
            onClick={() => saveCourseContent(course.id)}
            className="rounded-md cursor-pointer"
            src={course.thumbnail}
            alt=""
          />
        )}
        <div className="text-center">
          <h3 className="text-xl font-bold text-[--h3-color]">
            {course.title}
          </h3>
          <p className="text-md font-semibold text-[--p-color]">
            {course.about}
          </p>
        </div>
        {type === "free" ? (
          <a
            target="_blank"
            rel="noreferrer"
            className="text-center text-white font-semibold px-20 py-3 rounded bg-[--button-color]"
            href={course.source}
          >
            Watch
          </a>
        ) : (
          <>
            {!addedToCart ? (
              <button
                className="text-center text-white font-semibold px-20 py-3 rounded bg-[--button-color]"
                onClick={() => addToCart()}
              >
                Add to cart
              </button>
            ) : (
              <button
                className="text-center text-white font-semibold px-20 py-3 rounded bg-[--button-color]"
                onClick={() => removeItem()}
              >
                Remove item
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CourseBox;
