import React, { useState } from "react";
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowUp } from "react-icons/io";

const SideBar = ({
  visibleSidebar,
  setIsVisisbleSidebar,
  videoData,
  setActiveVideo,
}) => {
  const [videoSectionVisible, setVideoSectionVisible] = useState({});
  const [videoCompleted, setVideoCompleted] = useState({});

  const toggleVideoSection = (sectionId) => {
    setVideoSectionVisible((prevVisibility) => ({
      ...prevVisibility,
      [sectionId]: !prevVisibility[sectionId],
    }));
  };

  const videoComplete = (videoId) => {
    setVideoCompleted((prev) => ({
      ...prev,
      [videoId]: !prev[videoId],
    }));
  };

  return (
    <>
      <div
        className={`sideBar-container transition-all delay-200 overflow-y-scroll scrollbar-hide select-none bg-white shadow-xl w-4/5 md:w-5/12 lg:w-1/4 h-[calc(100vh-4rem)] py-2 fixed top-16 ${
          visibleSidebar ? "left-0" : "-left-[100rem]"
        } z-30`}
      >
        {/* coure content title  */}
        <h1 className="px-4 font-semibold text-md flex items-center justify-between py-3 border-b-[1px] border-b-gray-400">
          Course Content{" "}
          <RxCross2
            className="cursor-pointer"
            onClick={() => setIsVisisbleSidebar(false)}
            size={20}
          />
        </h1>

        {/* mapping the video data to show  */}
        {videoData ? (
          <>
            {videoData.map((val, index) => (
              <>
                <div className="border-b-[1px] border-b-gray-300">
                  {/* video section title  */}
                  <h1
                    className="px-6 font-semibold underline bg-gray-100 py-4 flex items-center justify-between cursor-pointer"
                    key={index+"heading"}
                    onClick={() => toggleVideoSection(val.sectionId)}
                  >
                    Section {index + 1} : {val.sectionTitle}
                    <IoIosArrowUp
                      size={20}
                      className={`cursor-pointer transition-all delay-150 ${
                        !videoSectionVisible[val.sectionId] ? "rotate-180" : ""
                      }`}
                    />
                  </h1>

                  {/* each videos  */}
                  {val.sectionVideos.map((video, i) => (
                    // section video topics container
                    <div
                      key={i+"eachvideo"}
                      className={`videoSection transition-all ${
                        !videoSectionVisible[val.sectionId] ? "hidden" : ""
                      }`}
                    >
                      <div className="flex item-start gap-4 cursor-pointer hover:bg-gray-300 py-2 px-3">
                        {/* blank checkbox  */}
                        <GrCheckbox
                          key={i+"blankcheckbox"}
                          className={`blankCheckBox mt-1 ${
                            videoCompleted[video.videoId] ? "hidden" : ""
                          }`}
                          onClick={() => videoComplete(video.videoId)}
                          size={18}
                        />{" "}
                        {/* filled checkbox  */}
                        <GrCheckboxSelected
                          key={i+"filledcheckbox"}
                          className={`filledCheckBox mt-1 ${
                            videoCompleted[video.videoId] ? "" : "hidden"
                          }`}
                          onClick={() => videoComplete(video.videoId)}
                          size={18}
                        />
                        {/* video title and duration  */}
                        <div
                          onClick={() =>
                            setActiveVideo({
                              sectionIndex: index,
                              videoIndex: i,
                            })
                          }
                          className="w-full"
                        >
                          <span className="text-sm">{video.videoTitle}</span>
                          <div className="flex items-center gap-1">
                            <MdOutlineOndemandVideo size={15} />{" "}
                            <span className="text-xs mt-1">
                              {video.videoDuration}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ))}
          </>
        ) : (
          <>
            <p className="text-center font-semibold text-2xl">
              No Videos Available
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default SideBar;
