import React, { useState } from "react";
import { IoIosGlobe } from "react-icons/io";
import { useDispatch } from "react-redux";
import { addtoCart, removeFromCart } from "../redux/cart/cartSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CourseContentBox = ({ CourseDetails, starCountReturn, courseLanguages }) => {
  const dispatch = useDispatch();

  const checkItemPresent = () => {
    let cartItems = JSON.parse(localStorage.getItem("userCart"));
    let foundItem = cartItems.find(
      (x) => x.id === CourseDetails.id
    );
    
    if (foundItem === undefined || foundItem === null) {
      return false;
    } else {
      return true;
    }
  };
  const [addedToCart, setAddedToCart] = useState(checkItemPresent());

  const AddItemToCart = () => {
    dispatch(addtoCart(CourseDetails));
    setAddedToCart(checkItemPresent());
    toast.success("Added to cart", {
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const RemoveFromCart = () => {
    dispatch(removeFromCart({ id: CourseDetails.id }));
    setAddedToCart(checkItemPresent());
    toast.warn("Removed from cart", {
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div className="grid lg:block">
      
    {/* toast container  */}
      <ToastContainer />

      <div className="static place-self-center lg:absolute top-12 right-32 w-[90%] md:w-4/5 lg:max-w-[25vw] min-h-[60vh] h-fit z-30 bg-gray-100 shadow-lg rounded overflow-hidden">
        {/* course thumbnail  */}
        <div className="w-full h-fit object-cover relative overflow-hidden">
          <img
            className="group"
            src={CourseDetails.thumbnail}
            alt="course-thumbnail"
          />
          <div className="absolute p-2 left-0 right-0 z-30 bottom-0 text-center text-white bg-black bg-opacity-40 backdrop:blur font-semibold">
            Course Preview
          </div>
        </div>

        <div className="h-fit p-4 lg:hidden">
          <div>
            {/* cousrsetitle  */}
            <h1 className="text-2xl md:text-3xl font-semibold my-3">
              {CourseDetails.title}
            </h1>
            {/* course about  */}
            <h3 className="text-sm md:text-lg font-medium my-3">
              {CourseDetails.about}
            </h3>
          </div>

          <div>
            <div className="flex gap-1 justify-end">
              {/* ratings of course  */}
              <span className="font-semibold text-sm">
                {CourseDetails.ratings}{" "}
              </span>
              {/* starts function  */}
              <span className="flex">{starCountReturn()}</span>
            </div>
            <div className="flex flex-wrap my-2 items-center justify-between">
              {/* creator of course  */}
              <span className="text-xs">
                Created by{" "}
                <span className="text-blue-500 underline font-medium">
                  {CourseDetails.courseCreator}
                </span>
              </span>
              {/* languages the course available in  */}
              <span className="flex items-center">
                <IoIosGlobe className="m-2" />{" "}
                <span className="flex gap-1 text-sm">
                <span>{courseLanguages}</span>
                </span>
              </span>
            </div>
          </div>
        </div>

        {/* course price div  */}
        <div className="p-4">
          <div className="flex items-center justify-between my-3">
            <p className="font-semibold">Effective price</p>
            <h3 className="font-semibold text-xl">
              <span className="text-sm line-through text-gray-700 m-2">
                &#8377;{CourseDetails.previousPrice}
              </span>
              <span className="text-[--price-color]">
                &#8377;{CourseDetails.price}
              </span>
            </h3>
          </div>

          {/* apply cupon and add to card btn  */}
          <div className="my-6 border-2 border-blue-500 rounded">
            <div className="flex items-center">
              <input
                className="p-2 w-full outline-none"
                type="text"
                placeholder="enter cupon"
              />{" "}
              <button className="py-2 px-4 bg-[--button-color] hover:bg-[--button-hover-color] text-white font-semibold">
                Apply
              </button>
            </div>
          </div>

          {addedToCart ? (
            <button
              onClick={RemoveFromCart}
              className="p-2 w-full rounded border-2 border-blue-400 font-semibold transition-all delay-50 hover:bg-[--button-color] hover:text-white"
            >
              Remove from cart
            </button>
          ) : (
            <button
              onClick={AddItemToCart}
              className="p-2 w-full rounded border-2 border-blue-400 font-semibold transition-all delay-50 hover:bg-[--button-color] hover:text-white"
            >
              Add to cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseContentBox;
