import React from "react";
import CourseBox from "./CourseBox";

const Courses = ({ course }) => {
  return (
    <section className="px-8 py-12 bg-gradient-to-l from-gray-300 to-gray-50">
      {/* courses heading  */}
      <h1 className="text-center text-3xl text-[--h1-color] font-semibold">
        Available Courses
      </h1>

      {/* courses container  */}
      <div className="grid grid-cols-1 gap-4 py-6 md:grid-cols-2 lg:grid-cols-4">
        {/* course box  */}

        {course.map((m, i) => (
          <>
            <CourseBox key={i+"coursebox"} type="free" course={m} />
          </>
        ))}
      </div>
    </section>
  );
};

export default Courses;
