import React, { useEffect, useState } from "react";
import CodingImage from "../assets/5522937.jpg";
import { Link, useNavigate } from "react-router-dom";
import { MdEmail, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { IoMdPhonePortrait } from "react-icons/io";
import { PiPasswordDuotone, PiPasswordFill } from "react-icons/pi";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import axios from "axios";
import {
  userAuthenticated,
  getRandomId,
  signUpValidation,
} from "../utils/util";
import { IoHomeOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup() {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    const validUser = userAuthenticated(document.cookie);
    validUser ? navigate("/home") : navigate("/signup");
    // eslint-disable-next-line
  }, []);

  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleConfPass, setVisibleConfPass] = useState(false);
  // user input data
  const [inputData, setInputData] = useState({
    txnId: getRandomId(),
    name: "",
    email: "",
    phone: "",
    pass: "",
    confPass: "",
  });

  // sending data to signup api
  axios.defaults.withCredentials = true;
  const handleRegister = (e) => {
    e.preventDefault();
    const validation = signUpValidation(inputData);
    if (validation === true) {
      setLoading(true);
      axios
        .post("http://localhost:5000/api/signup", inputData)
        .then((response) => {
          if (response.data.apiResponse === 0 && response.data.data === true) {
            toast.success("Login now", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            navigate("/login");
          } else {
            toast.error("Failed", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        });
    } else {
      toast.warn(`${validation}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      {/* main div  */}
      {/* <!-- component --> */}
      <div className="bg-[--form-bg-color] font-semibold flex justify-center items-center h-screen overflow-hidden relative">
        {/* toast container  */}
        <ToastContainer />

        {/* back to landing page link  */}
        <Link
          to={"/"}
          className="absolute text-blue-800 lg:text-white top-12 -left-24 transition-all delay-75 hover:left-2 z-10 flex gap-4 py-2 px-4 backdrop-blur bg-black lg:bg-white bg-opacity-15 lg:bg-opacity-30 rounded-full"
        >
          Go Back
          <IoHomeOutline size={20} />
        </Link>

        {/* <!-- Left: Image --> */}
        <div className="w-4/5 h-screen hidden lg:block relative">
          <img
            src={CodingImage}
            alt=""
            className="object-cover w-full h-full"
          />

          {/* box : heading and tagline  */}
          <div className="absolute top-[15%] left-1/2 -translate-x-1/2">
            <h1 className="text-4xl text-[--heading-color]">Keep it special</h1>
            <p className="text-xl text-[--tagline-color]">
              Capture your personal memory in unique way, anywhere.
            </p>
          </div>
        </div>

        {/* <!-- Right: Login Form --> */}
        <div className="lg:p-36 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <h2 className="text-3xl font-bold mb-2 text-[--font-color]">
            Codemap
          </h2>
          <p className="mb-4 font-semibold">Create An Account With Few Steps</p>
          <form onSubmit={handleRegister}>
            {/* <!-- full name Input --> */}
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-600">
                Full Name{" "}
              </label>
              <div className="relative inline-block w-full">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 pl-10"
                  autoComplete="off"
                  placeholder=" "
                  onChange={(e) =>
                    setInputData({ ...inputData, name: e.target.value })
                  }
                />

                {/* full name input icon  */}
                <BsFillPersonLinesFill className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2" />
              </div>
            </div>

            {/* <!-- email Input --> */}
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-600">
                Email{" "}
              </label>
              <div className="relative inline-block w-full">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 pl-10"
                  autoComplete="off"
                  placeholder=" "
                  onChange={(e) =>
                    setInputData({ ...inputData, email: e.target.value })
                  }
                />

                {/* email input icon  */}
                <MdEmail className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2" />
              </div>
            </div>

            {/* <!-- phone Input --> */}
            <div className="mb-4">
              <label htmlFor="phone" className="block text-gray-600">
                Phone Number{" "}
              </label>
              <div className="relative inline-block w-full">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 pl-10"
                  autoComplete="off"
                  placeholder=" "
                  onChange={(e) =>
                    setInputData({ ...inputData, phone: e.target.value })
                  }
                />

                {/* phone num input icon  */}
                <IoMdPhonePortrait className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2" />
              </div>
            </div>

            {/* <!-- Password Input --> */}
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-600">
                Password{" "}
              </label>
              <div className="relative inline-block w-full">
                <input
                  type={`${visiblePass ? "text" : "password"}`}
                  id="password"
                  name="password"
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 pl-10"
                  autoComplete="off"
                  placeholder=" "
                  onChange={(e) =>
                    setInputData({ ...inputData, pass: e.target.value })
                  }
                />

                {/* password input icon  */}
                <PiPasswordDuotone className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2" />

                {/* hide pass icon  */}
                {inputData.pass !== "" ? (
                  <>
                    <IoEyeOutline
                      title="hide"
                      onClick={() => setVisiblePass(false)}
                      className={`${
                        visiblePass ? "" : "hidden"
                      } absolute top-1/2 right-4 -translate-y-1/2 select-none cursor-pointer`}
                    />
                  </>
                ) : (
                  <></>
                )}

                {/* unhide pass icon  */}
                <IoEyeOffOutline
                  title="unhide"
                  onClick={() => setVisiblePass(true)}
                  className={`${
                    visiblePass ? "hidden" : ""
                  } absolute top-1/2 right-4 -translate-y-1/2 select-none  cursor-pointer`}
                />
              </div>
            </div>

            {/* <!-- Confirm Password Input --> */}
            <div className="mb-4">
              <label htmlFor="confPassword" className="block text-gray-600">
                Confirm Password{" "}
              </label>
              <div className="relative inline-block w-full">
                <input
                  type={`${visibleConfPass ? "text" : "password"}`}
                  id="confPassword"
                  name="password"
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 pl-10"
                  autoComplete="off"
                  placeholder=" "
                  onChange={(e) =>
                    setInputData({ ...inputData, confPass: e.target.value })
                  }
                />

                {/* confirm password input icon  */}
                <PiPasswordFill className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2" />

                {/* hide pass icon  */}
                {inputData.confPass !== "" ? (
                  <IoEyeOutline
                    title="hide"
                    onClick={() => setVisibleConfPass(false)}
                    className={`${
                      visibleConfPass ? "" : "hidden"
                    } absolute top-1/2 right-4 -translate-y-1/2 select-none cursor-pointer`}
                  />
                ) : (
                  <></>
                )}

                {/* unhide pass icon  */}
                <IoEyeOffOutline
                  title="unhide"
                  onClick={() => setVisibleConfPass(true)}
                  className={`${
                    visibleConfPass ? "hidden" : ""
                  } absolute top-1/2 right-4 -translate-y-1/2 select-none cursor-pointer `}
                />
              </div>
            </div>

            {/* <!-- sign in Button --> */}
            {Loading ? (
              <button
                type="button"
                class="flex justify-center items-center px-4 py-2 w-full font-semibold leading-6 text-white transition duration-150 ease-in-out bg-blue-700 rounded-md shadow cursor-not-allowed hover:bg-blue-800"
                disabled
              >
                <svg
                  class="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className="bg-[--button-color] hover:bg-blue-600 text-white font-semibold rounded-md py-2 px-4 w-full"
              >
                Sign Up
              </button>
            )}
          </form>

          {/* <!-- Sign up  Link --> */}
          <div className="mt-6 text-[--anchortag-color] text-center">
            <p className="text-black">
              Already Registered?
              <MdOutlineKeyboardDoubleArrowRight className="h-5 w-5 inline-block" />
              <Link
                to="/login"
                className="hover:underline text-[--anchortag-color]"
              >
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
